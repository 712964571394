<template>
	<Container>
		
		<LoadingPane v-bind="{ loading }" containerClass="md:flex md:space-x-6 pt-4">
			
			<!-- Category list -->
			<!--
			<div class="md:w-1/3 md:flex-grow-0">
				<CategoryList v-bind="{ categories }" @selected="catSelected" @deselect="deselect" />
			</div>
			<hr class="md:hidden">
			-->
			
			
			<div class="md:flex-grow w-full">
				
				<GridProductView v-bind="{ activeCat }" />
				
			</div>
			
			
			
		</LoadingPane>
	</Container>
</template>
<script>
import Container from '@/layouts/Container'
import LoadingPane from '@leaf/ui/components/LoadingPane'
import CategoryList from './CategoryList'
import GridProductView from './GridProductView'
export default {
	components: {
		Container,
		LoadingPane,
		CategoryList,
		GridProductView,
	},
	data() {
		return {
			loading: true,
			
			activeCat: null,
			categories: [],
		};
	},
	methods: {
		deselect() { this.activeCat = null; },
		catSelected(c) { this.activeCat = c; }
	},
	async mounted() {
		this.loading = true;
		try {
			
			this.categories = await this.$api.request('GET', `categories`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page. Please try again.');
		}
		this.loading = false;
	}
}
</script>