<template>
	<div class="w-full overflow-hidden">
		
		<img class="mx-auto w-full object-cover" src="@/assets/images/packaging.jpg" style="height:500px">
		<div class="w-full">
			<Container>
				<div class="mt-4">
					<div class="md:text-4xl md:leading-normal text-3xl font-medium font-atma">Premium Handmade Products</div>
					<div class="leading-snug mb-2 text-gray-600">
						At Sewlightly, we hand craft our products with care.
						Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus natus deleniti earum adipisci corrupti molestiae magnam quaerat voluptates ipsam porro sapiente aliquid, voluptate magni nam maiores commodi accusamus rem non.
					</div>
				</div>
			</Container>
		</div>
	</div>
</template>
<script>
import Container from '@/layouts/Container'
export default {
	components: {
		Container
	}
}
</script>