<template>
	<Container>
		<LoadingPane v-bind="{ loading }" containerClass="lg:flex py-4 lg:space-x-4">
			<div class="lg:w-1/3 lg:mb-0 mb-4">
				<ImageCycler v-bind="{ images }" />
			</div>
			
			
			
			<div class="lg:flex-grow lg:px-4 lg:border-l lg:border-gray-200">
				<ProductDetails v-if="product" v-bind="{ product }" />
			</div>	
			
		</LoadingPane>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container'
import LoadingPane from '@leaf/ui/components/LoadingPane'
import ImageCycler from './ImageCycler'
import ProductDetails from './ProductDetails'

export default {
	props: [ 'productID' ],
	components: {
		Container,
		LoadingPane,
		ImageCycler,
		ProductDetails,
	},
	data() {
		return {
			loading: true,
			product: null,
			images: [],
		};
	},
	async mounted() {
		this.loading = true;
		try {
			const product = await this.$api.request('GET', `products/${this.productID}/details`)
			this.product = product;
			this.images = product.images.map(img => {
				img.imageURL = `${process.env.VUE_APP_STORAGE_BASEURL}/${img.imageURL}`;
				return img;
			});
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading product. Please try again.');
		}
		this.loading = false;
	}
}
</script>