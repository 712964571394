<style lang="scss" scoped>

// List transitions
.toast-enter,
.toast-enter-active,
.toast-leave-active {
	transition: all 0.5s ease;
	position: absolute;
}

.toast-enter, .toast-leave-active,		// For Vue 2
.toast-enter-from,
.toast-leave-to {
	opacity: 0;
	transform: translateY(-10px);
}
.toast-move {
	transition: all 0.5s ease;
}

</style>
<template>
	<div class="fixed flex pointer-events-none w-full pt-4 md:pt-20 px-2 md:px-0 z-50">
		<transition-group name="toast" tag="div" class="relative toast-list w-full md:w-4/5 mx-auto text-center" :class="containerClass[align]">
			<div v-for="t in toasts" :key="t.id" class="w-full mb-2">
				<div class="inline-block mx-auto text-white px-4 py-2 rounded text-lg shadow-md" :class="toastClass[t.level]">{{ t.message }}</div>
			</div>
		</transition-group>
	</div>
</template>
<script>
import { emitter } from '../../utils'

export default {
	data() {
		return {
			duration: 1000,
			maxToasts: 10,
			align: 'center',
			
			// List of active toast messages
			toasts: [],
			
			// Classes
			containerClass: {
				left: 'md:text-left',
				right: 'md:text-right',
				center: 'md:text-center',
			},
			toastClass: {
				success: 'bg-success-500',
				error: 'bg-danger-500',
				info: 'bg-gray-800',
			}
		};
	},
	methods: {
		makeID(length = 10) {
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			const poolLen = characters.length;
			let result = '';
			for (let i = 0; i < length; ++i) {
				result += characters.charAt(Math.floor(Math.random() * poolLen));
			}
			return result;
		},
		
		addToast({ level, message }) {
			
			const toastID = this.makeID(10);
			this.toasts.push({
				id: toastID,
				level,
				message,
			});
			
			// Remove earlier toasts if max amount reached
			if (this.toasts.length > this.maxToasts) {
				this.toasts.shift();
			}
			
			// Remove toast after duration
			setInterval(() => {
				this.removeToast(toastID);
			}, this.duration);
		},
		
		removeToast(id) {
			for (const i in this.toasts) {
				if (this.toasts[i].id == id) {
					this.toasts.splice(i, 1);
					break;
				}
			}
		}
	},
	mounted() {
		// Set options
		const options = this.$toast.options;
		this.duration = options.duration;
		this.maxToasts = options.maxToasts || 10;
		this.align = options.align || 'right';
		
		emitter.on('toast/add', this.addToast);
	}
}
</script>