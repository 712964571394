import ProductList from './ProductList/ProductList'
import ProductView from './ProductView/ProductView'

export default [{
	path: '/products',
	component: ProductList,
}, {
	path: '/product/:productID',
	component: ProductView,
	props: true,
}]