import Pagination from '../components/Pagination'

export default {
	components: { Pagination },
	data() {
		return {
			pageLimit: parseInt(process.env.VUE_APP_DEFAULT_PAGE_LIMIT),
			totalRows: 0,
			curPage: 0,
		}
	}
}