<template>
	<Modal v-model="shown">
		<!-- Title -->
		<div class="text-xl font-semibold mb-2">{{ title }}</div>
		
		<!-- Message -->
		<div class="whitespace-pre-wrap">{{ message }}</div>
		<hr>
		
		<!-- Buttons -->
		<div>
			<button
				v-for="b in buttons"
				@click="close(b.value)"
				:class="b.btnClass"
				class="button mr-2 last:mr-0">
				{{ b.label }}
			</button>
		</div>
		
		
	</Modal>
</template>
<script>
import Modal from '../../components/Modal'
import { emitter } from '../../utils'


export default {
	components: {
		Modal
	},
	data() {
		return {
			shown: false,
			
			title: '',
			message: '',
			buttons: [],
			
			callback: null,
			
			
			buttonPresets: {
				// Single OK button
				OK: [{ label: 'OK', btnClass: 'is-success', value: true },],
				
				// OK and cancel button
				OK_CANCEL: [
					{ label: 'OK', btnClass: 'is-success', value: true },
					{ label: 'Cancel', value: false }
				],
				
				// Same as OK_CANCEL, but with Submit instead of OK
				// Used for form submission confirmation
				SUBMIT_CANCEL: [
					{ label: 'Submit', btnClass: 'is-success', value: true },
					{ label: 'Cancel', value: false }
				],
				
				// Yes/no button with success and danger class
				YES_NO: [
					{ label: 'Yes', btnClass: 'is-success', value: true },
					{ label: 'No', btnClass: 'is-danger', value: false },
				],
			}
		};
	},
	methods: {
		open({ title, message, buttons, callback }) {
			this.shown = true;
			
			this.title = title;
			this.message = message;
			
			
			if (typeof buttons == 'string') {
				// It's a preset
				const preset = this.buttonPresets[buttons];
				if (preset === undefined) throw new Error('Invalid button preset');
				
				// Assign preset
				this.buttons = preset;
				
			} else {
				// Customizable buttons!
				this.buttons = buttons;
			}
			
			
			this.callback = callback;
		},
		
		// Close the dialog with response
		close(res) {
			if (this.callback) this.callback(res);
			this.shown = false;
		}
	},
	mounted() {
		emitter.on('confirm-dialog/open', this.open);
	}
}
</script>