<template>
	<div>
		<div class="text-3xl mb-4 font-medium font-atma">Featured Products</div>
		
		<div class="grid md:grid-cols-4 grid-cols-2 gap-x-4">
			<div v-for="p in products" class="block shadow-md">
				<router-link :to="`/product/${p.productID}`">
					<Placeholder
						class="mx-auto"
						v-bind="{
							height: '240px',
							iconClass: 'text-gray-400 w-1/5 h-1/5',
							imgFit: 'object-cover',
							src: p.image.imageURL,
						}" />
					<!--<img width="300" height="300" :src="p.image.imageURL">-->
				</router-link>
				<div class="px-4 py-2">
					
					<!-- Link -->
					<router-link
						:to="`/product/${p.productID}`"
						class="block font-semibold font-atma text-xl leading-normal text-gray-500 hover:text-primary-400 transition-colors">
						{{ p.productName }}
					</router-link>
					
					
					<div class="leading-snug text-sm text-gray-400">{{ p.description }}</div>
				</div>
			</div>
			
		</div>
		
	</div>
</template>
<script>
import Placeholder from '@leaf/ui/components/Placeholder'
export default {
	props: [ 'products' ],
	components: {
		Placeholder,
	},
	data() {
		return {
			/*
			products: [{
				productID: 'pouch-1',
				name: 'Pouch 1',
				description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut, totam?',
				imageSrc: '/assets/products/pouch.jpg',
			}, {
				productID: 'pouch-2',
				name: 'Pouch 2',
				description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut, totam?',
				imageSrc: '/assets/products/pouch2.jpg'
			}, {
				productID: 'scrunchie',
				name: 'Scrunchie',
				description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut, totam?',
				imageSrc: '/assets/products/scrunchie1.jpg'
			}, {
				productID: 'hairband',
				name: 'Hairband',
				description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut, totam?',
				imageSrc: '/assets/products/hairband.jpg'
			}]
			*/
		}
	}
}
</script>