<template>
	<svg class="fill-current align-middle overflow-hidden w-4 h-4" :class="classes" viewBox="0 0 24 24" :style="styles">
		<path :d="data"></path>
	</svg>
</template>

<script>
export default {
	props: {
		data: String,
		size: {
			type: Number,
			default: 18
		},
		display: {
			type: String,
			default: 'inline-block'
		},
		marginTop: {
			type: String,
			default: '-0.125em'
		}
	},
	computed: {
		classes() {
			return [ this.display ]
		},
		styles() {
			return {
				'margin-top': this.marginTop
			}
		}
	}
}
</script>
