import REST from '@leaf/ui/plugins/REST'

const rest = new REST({
	host: process.env.VUE_APP_STOCKLY_API_HOST,
});


// Set stockly API key
rest.setHeader('ApiKey', process.env.VUE_APP_STOCKLY_API_KEY);

export default rest;