import axios from 'axios'

const defaultResInterceptor = (res) => res.data;

export default function(options) {
	let _authType = options.authType || 'Authorization';
	let _token = options.token || null;
	
	const _headers = {};
	
	// Init axios instance
	const _client = axios.create({
		baseURL: options.host,
		...options.axiosOpts,
	});
	
	
	
	
	// Response interceptor if any, or return errors
	const resInterceptor = options.transformResponse || defaultResInterceptor;
	_client.interceptors.response.use(resInterceptor, err => {
		let errMsg = 'Unknown Error: ' + err.message;
		
		// Response is a valid response from the server, return the error message
		if (err.response) errMsg = err.response.data;
		else if (err.request) errMsg = 'Request error: ' + err.message;
		
		return Promise.reject(errMsg);
	});
	
	
	
	this.setToken = (token, authType) => {
		_token = token;
		if (authType) _authType = authType;
	},
	
	// Used to set additional header for every request
	// To unset, put value as undefined
	this.setHeader = (key, value) => _headers[key] = value;
	
	
	this.request = (method, path, data, requestHeaders) => {
		// Default headers
		let headers = _headers;
		
		// Add authorization header if token is set
		if (_token) headers[_authType] =  _token;
		
		if (requestHeaders) {
			// Merge extra headers with our current headers
			headers = {
				...headers,
				...requestHeaders,
			};
		}
		
		
		// Return request promise
		return _client.request({
			method,
			url: path,
			data,
			headers
		});
	}
}