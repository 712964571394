import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home/Home'
import About from '@/views/About/About'

import GalleryRoutes from '@/views/Gallery/routes'
import ProductRoutes from '@/views/Products/routes'

const routes = [
	{
		path: '/',
		component: Home
	}, {
		path: '/about',
		component: About
	},
	
	...GalleryRoutes,
	...ProductRoutes,
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router;
