<template>
	<Container>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container'

export default {
	components: {
		Container,
	}
}
</script>