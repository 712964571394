<style lang="scss" scoped>
.shopee-btn {
	background: #ee4d2d;
	@apply px-3 py-1 text-white text-sm rounded transition-colors;
	&:focus{ @apply outline-none; }
	&:hover { background: #f05d40; }
}
</style>
<template>
	<div>
		<div>
			<div class="text-3xl font-atma">{{ product.productName }}</div>
			<div class="text-lg text-gray-400">{{ categoryString }}</div>
			
			<div class="md:flex md:items-center md:justify-between my-2">
				<!-- Product variant selection -->
				<!--
				<select class="select py-1 w-full md:w-1/5 text-sm">
					<option value="">Select variant</option>
					<option v-for="v in variants" :value="v.variantID">{{ v.variantName }}</option>
				</select>
				-->
				
				<!-- Shopee button -->
				<a :href="shopeeLink" target="_blank" class="shopee-btn inline-flex items-center">
					<icon :data="shopeeIcon" style="margin-top:0" class="w-5 h-5 mr-1" />
					<span>Buy on Shopee</span>
				</a>
			</div>
			
		</div>
		<hr>
		
		<div class="font-light text-gray-800 whitespace-pre-wrap">
			{{ product.description }}
		</div>
		
	</div>
</template>
<script>
export default {
	props: [ 'product' ],
	computed: {
		categoryString() {
			if (!this.product) return '';
			
			let res = 'Uncategorized';
			if (this.product.category) res = this.product.category.catName;
			if (this.product.subcategory) res = `${res}/${this.product.subcategory.catName}`;
			return res;
		},
		variants() {
			if (!this.product) return [];
			return this.product.variants;
		},
		shopeeLink() {
			if (!this.product) return '';
			const link = this.product.shopLinks.find(sl => sl.shopKey == 'shopee');
			if (!link) return '';
			return link.url;
		}
	},
	data() {
		return {
			shopeeIcon: 'M12.107,14.969c-0.117,0.96-0.697,1.73-1.602,2.113c-0.498,0.215-1.175,0.332-1.707,0.293c-0.832-0.031-1.616-0.231-2.336-0.603c-0.257-0.132-0.643-0.395-0.937-0.645c-0.076-0.063-0.082-0.102-0.033-0.174c0.026-0.039,0.077-0.111,0.183-0.272c0.159-0.229,0.179-0.26,0.196-0.286c0.051-0.078,0.131-0.083,0.211-0.02c0.01,0.007,0.01,0.007,0.017,0.012c0.012,0.011,0.012,0.011,0.044,0.033c0.032,0.025,0.051,0.039,0.059,0.045c0.777,0.608,1.683,0.96,2.595,0.994c1.272-0.018,2.186-0.589,2.352-1.465c0.182-0.967-0.579-1.803-2.063-2.269c-0.464-0.144-1.638-0.612-1.853-0.742c-1.016-0.595-1.49-1.376-1.424-2.341c0.104-1.335,1.345-2.332,2.914-2.339c0.701-0.002,1.4,0.144,2.071,0.427c0.237,0.1,0.664,0.333,0.81,0.442c0.082,0.063,0.102,0.134,0.053,0.212c-0.029,0.046-0.074,0.117-0.166,0.266l-0.003,0.003c-0.124,0.197-0.127,0.202-0.156,0.246c-0.049,0.078-0.104,0.083-0.192,0.027c-0.723-0.484-1.519-0.728-2.396-0.745C7.654,8.206,6.836,8.854,6.783,9.741c-0.015,0.799,0.586,1.381,1.879,1.826C11.287,12.417,12.293,13.406,12.107,14.969 M8.86,1.167c1.711,0,3.105,1.626,3.175,3.66H5.689C5.753,2.791,7.148,1.167,8.86,1.167 M17.72,5.208c0-0.213-0.173-0.383-0.381-0.383h-4.111C13.129,2.141,11.209,0,8.86,0C6.511,0,4.594,2.141,4.492,4.824H0.376C0.168,4.83,0.004,4.998,0.004,5.208c0,0.01,0,0.02,0,0.029H0l0.588,12.939c0,0.038,0.002,0.074,0.004,0.11c0.001,0.007,0.001,0.018,0.003,0.023v0.027l0.001,0.001c0.089,0.9,0.744,1.628,1.631,1.659l0.004,0.001h13.059C15.298,20,15.301,20,15.311,20c0.007,0,0.01,0,0.02-0.002h0.029l0,0c0.903-0.025,1.637-0.76,1.709-1.67l0,0l0.006-0.016c0-0.007,0-0.017,0.001-0.026c0-0.024,0.003-0.046,0.003-0.068L17.72,5.227h-0.003C17.72,5.22,17.72,5.215,17.72,5.208',
		}
	}
}
</script>