import ToastContainer from './ToastContainer'
import { emitter } from '../../utils'

export default {
	install(app, options) {
		if (this.installed) return;
		this.installed = true;
		app.component('toast-container', ToastContainer);
		
		const handler = {
			options,
			success: (message) => emitter.emit('toast/add', { level: 'success',  message }),
			error: (message) => emitter.emit('toast/add', { level: 'error', message }),
			info: (message) => emitter.emit('toast/add', { level: 'info', message }),
		};
		
		
		const version = Number(app.version.split('.')[0]);
		if (version == 3) app.config.globalProperties.$toast = handler;
		else if (version == 2) app.prototype.$toast = handler;
	}
}