<template>
	<div class="">
		
		<HeroHeader />
		
		<Container>
			<transition name="fade" mode="out-in">
				<div v-if="!loading && featuredProducts.length > 0" class="my-10" >
					<ProductShowcase v-bind="{ products: featuredProducts }" />
				</div>
			</transition>
			
			<hr class="mt-20 mb-6">
			<div class="pb-10 text-center text-sm text-gray-600">
				&#169; Sewlightly 2020
			</div>
		</Container>
		
	</div>
</template>
<script>
import Container from '@/layouts/Container'
import HeroHeader from './HeroHeader'
import ProductShowcase from './ProductShowcase'
export default {
	components: {
		Container,
		HeroHeader,
		ProductShowcase,
	},
	data() {
		return {
			loading: true,
			featuredProducts: []
		}
	},
	async mounted() {
		this.loading = true;
		try {
			const featured = await this.$api.request('GET', `products/featured`);
			this.featuredProducts = featured.map(f => {
				if (f.image) f.image.imageURL = `${process.env.VUE_APP_STORAGE_BASEURL}/${f.image.imageURL}`;
				return f;
			});
		} catch(err) {
			console.error(err);
		}
		this.loading = false;
	}
}
</script>