<template>
	<div class="pb-4 shadow rounded-lg overflow-hidden">
		
		<router-link :to="`/product/${product.productID}`">
			<Placeholder
				class="mx-auto"
				v-bind="{
					height: '240px',
					iconClass: 'text-gray-400 w-1/5 h-1/5',
					imgFit: 'object-cover',
					src: imageSrc,
				}" />
		</router-link>
		
		
		<div class="text-center">
			<div class="mt-2 mb-1">
				<router-link class="text-gray-500 hover:text-primary-400" :to="`/product/${product.productID}`">{{ product.productName }}</router-link>
			</div>
			<div class="text-xl font-medium">{{ priceString }}</div>
		</div>
		
	</div>
</template>
<script>
import Placeholder from '@leaf/ui/components/Placeholder'
export default {
	props: [ 'product' ],
	components: {
		Placeholder,
	},
	computed: {
		imageSrc() {
			if (!this.product || !this.product.image) return '';
			const image = this.product.image;
			return `${process.env.VUE_APP_STORAGE_BASEURL}/${image.imageURL}`;
		},
		priceString() {
			if (!this.product) return '-';
			const { minPrice, maxPrice } = this.product;
			if (minPrice == maxPrice) return `RM ${minPrice.toFixed(2)}`;
			else return `RM ${minPrice.toFixed(2)} - RM ${maxPrice.toFixed(2)}`;
		}
	}
}
</script>