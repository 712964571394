<template>
	<figure :style="figureStyle">
		<div
			v-if="!src"
			:style="{ height: `${height}px` }"
			class="text-gray-600 flex justify-center items-center h-full">
			<icon v-bind="{ data: icon || mdiFileImage }" :class="iconClass" />
		</div>
		
		<img v-else draggable="false" :class="[imgClass, imgFit]" :src="src">
	</figure>
</template>
<script>
import { mdiFileImage } from '@mdi/js'
export default {
	props: {
		width: {
			type: String,
			default: 'auto',
		},
		height: {
			type: String,
			default: 'auto',
		},
		
		icon: String,
		iconClass: String,
		
		src: {
			type: [String, Object],
			default: null
		},
		imgClass: {
			type: String,
			default: 'w-full h-full'
		},
		imgFit: {
			type: String,
			default: 'object-cover'
		}
	},
	computed: {
		figureStyle() {
			const style = {
				width: this.width,
				height: this.height,
			};
			return style;
		}
	},
	data() {
		return {
			mdiFileImage,
		}
	}
}
</script>