<style lang="scss" scoped>
.video-section {
	min-height: 20rem;
}
</style>
<template>
	<Container class="pt-8">
		
		<!-- Featured video -->
		<transition name="fade" mode="out-in">
			<div :key="activeMedia ? activeMedia.mediaID : null" class="video-section md:flex md:items-stretch mb-4 space-x-4">
				
				<!-- Video player -->
				<div class="md:flex-grow md:mb-0 mb-4">
					<div class="w-full h-full relative">
						<iframe
							v-if="activeMedia"
							class="w-full md:absolute"
							height="340"
							:src="`https://www.youtube.com/embed/${activeMedia.ref}`"
							:title="activeMedia.title"
							frameborder="0"
							allowfullscreen
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
						</iframe>
					</div>
				</div>
				
				<!-- Video info -->
				<div v-if="activeMedia" class="md:w-1/2">
					<div class="font-medium text-2xl font-atma mb-4">{{ activeMedia.title }}</div>
					<div class="text-gray-500 whitespace-pre-wrap text-xs">
						{{ activeMedia.description }}
					</div>
				</div>
			</div>
		</transition>
		<hr>
		
		<LoadingPane v-bind="{ loading }">
			<div class="text-2xl mb-2 font-medium font-atma">More Videos</div>
			<div class="grid md:grid-cols-4 grid-cols-2 gap-4">
				<div
					v-for="(m, mi) in mediaList"
					:key="m.mediaID"
					:title="m.title"
					class="group cursor-pointer"
					@click="selectMedia(mi)">
					
					<!-- Media thumbnail -->
					<div class="w-full h-40 bg-black opacity-80 hover:opacity-100 transition-opacity">
						<img class="object-cover w-full h-full" :src="m.thumbnail" />
					</div>
					<div class="mt-1">
						<div class="text-sm font-medium text-center truncate text-gray-500 group-hover:text-black transition-colors">{{ m.title }}</div>
						<div class="text-xs text-gray-400">Posted on {{ m.created }}</div>
					</div>
					
				</div>
			</div>
		</LoadingPane>
		
		<!--
		<Modal v-model="showPlayer">
			<VideoPlayer />
		</Modal>
		-->
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container'
import Modal from '@leaf/ui/components/Modal'
import LoadingPane from '@leaf/ui/components/LoadingPane'
import Pagination from '@leaf/ui/mixins/Pagination'
import dayjs from 'dayjs'


export default {
	components: {
		Container,
		Modal,
		LoadingPane,
	},
	mixins: [ Pagination ],
	computed: {
		activeMedia() {
			if (this.activeIndex >= 0 && this.activeIndex < this.mediaList.length) {
				return this.mediaList[this.activeIndex];
			}
			return null;
		}
	},
	data() {
		return {
			loading: false,
			showPlayer: false,
			
			
			activeIndex: 0,
			mediaList: [],
		};
	},
	methods: {
		selectMedia(mi) {
			this.activeIndex = mi;
			if (this.activeIndex == -1) return;
			
		},
		
		
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				
				const res = await this.$api.request('GET', `gallery?${queries.join('&')}`);
				this.curPage = page;
				this.totalRows = res.total;
				
				this.mediaList = res.results;
				this.mediaList.forEach(m => {
					m.created = dayjs(m.created).format('D MMM YYYY')
				});
				
				// Select the first one automatically
				if (this.mediaList.length > 0) this.activeIndex = 0;
				else this.activeIndex = -1;
				
				
				this.selectMedia(this.activeIndex);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page')
			}
			this.loading = false;
		}
	},
	mounted() {
		this.loadPage(0);
	}
}
</script>