<template>
	<div class="select-none">
		<!-- Main image -->
		<div class="mb-2">
			<transition name="fade" mode="out-in">
				<Placeholder
					:key="activeImage ? activeImage.imageID : null"
					v-bind="{
						width: '380px',
						height: '380px',
						iconClass: 'text-gray-400 w-1/5 h-1/5',
						imgFit: 'object-cover',
						src: activeImage ? activeImage.imageURL : null,
					}" />
			</transition>
		</div>
		
		<!-- Thumbnails -->
		<div class="grid lg:grid-cols-4 md:grid-cols-6 grid-cols-4 gap-1">
			
			<div
				v-for="img in images"
				@click="activeImage = img"
				class="bg-white hover:opacity-100 transition-opacity cursor-pointer"
				:class="{ 'opacity-50': img != activeImage }">
				<Placeholder
					:key="img.imageID"
					class="bg-white"
					v-bind="{
						height: '100px',
						iconClass: 'text-gray-400 w-1/5 h-1/5',
						imgFit: 'object-cover',
						src: img.imageURL,
					}" />
			</div>
		</div>
		
	</div>
</template>
<script>
import Placeholder from '@leaf/ui/components/Placeholder'
export default {
	props: [ 'images' ],
	components: {
		Placeholder,
	},
	data() {
		return {
			activeImage: null,
		}
	},
	mounted() {
		if (this.images.length) {
			this.activeImage = this.images[0];
		}
	}
}
</script>