<style lang="scss" scoped>
.navbar-link {
	@apply px-4 pt-2 inline-flex items-center text-lg uppercase text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors;
}

.scrolled {
	@apply shadow-md;
}

</style>
<template>
	<nav class="w-full fixed top-0 bg-white transition-shadow duration-100 z-10 border-b border-gray-200" :class="{ scrolled: !atPageTop }">
		
		<Container class="w-full h-full flex items-stretch font-atma">
			<router-link class="inline-flex items-center mr-2" to="/">
				<img height="40" style="height:40px" src="@/assets/logo_hori_200.jpg">
			</router-link>
			
			<!-- Links -->
			<router-link to="/" class="navbar-link">Home</router-link>
			<router-link to="/products" class="navbar-link">Products</router-link>
			<router-link to="/gallery" class="navbar-link">Videos</router-link>
			<router-link to="/about" class="navbar-link">About Us</router-link>
			
			<div class="flex-grow"></div>
			
			<!-- Social media links -->
			<a v-for="sl in socialLinks" class="navbar-link" target="_blank" :href="sl.url">
				<Icon class="w-6 h-6" :data="sl.icon" />
			</a>
		</Container>
	
	</nav>
</template>
<script>
import Container from '@/layouts/Container'
import Icon from '@leaf/ui/components/Icon'
import { mdiFacebook, mdiInstagram } from '@mdi/js'
export default {
	components: {
		Icon,
		Container,
	},
	data() {
		return {
			icons: {
				mdiFacebook,
				mdiInstagram,
			},
			
			socialLinks: [{
				icon: mdiFacebook,
				url: 'https://www.facebook.com/Sewlightly-267758013897053',
			}, {
				icon: mdiInstagram,
				url: 'https://www.instagram.com/sewlightly/',
			}],
			
			atPageTop: true,
		}
	},
	methods: {
		handleScroll() {
			if (window.pageYOffset > 0) this.atPageTop = false;
			else this.atPageTop = true;
		}
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	}
}
</script>