import $api from '@/services/API'
import ConfirmDialog from '@leaf/ui/plugins/ConfirmDialog'
import Toast from '@leaf/ui/plugins/Toast'

import Icon from '@leaf/ui/components/Icon'

export default {
	async init(app) {
		
		// Register global components
		app.component('icon', Icon);
		
		
		// Toast message plugin
		app.use(Toast, { duration: 2000, align: 'center', maxToasts: 5 });
		
		
		// Default API request client
		app.use({
			install(app) { app.config.globalProperties.$api = $api; }
		});
		
		
		// Confirmation dialog
		app.use(ConfirmDialog);
		
	}
}