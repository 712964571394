<template>
	<transition name="fade" mode="out-in">
		<Preloader v-if="loading" class="my-6" />
		<div v-else :class="containerClass"><slot></slot></div>
	</transition>
</template>
<script>
import Preloader from './Preloader'
export default {
	props: [ 'loading', 'containerClass' ],
	components: {
		Preloader
	}
}
</script>