<template>
	<LoadingPane v-bind="{ loading }">
		
		<div class="mt-4 mb-4 pb-3 text-center border-b border-gray-200">
			<div class="text-2xl font-semibold text-gray-600 font-atma">{{ catTitle }}</div>
		</div>
		
		<!-- Grid view -->
		<div class="grid md:grid-cols-3 gap-4 grid-cols-1 pb-4">
			<ProductCard v-for="p in products" v-bind="{ product: p }" />
		</div>
		
		
		<!-- No products -->
		<div v-if="!products.length" class="text-center">
			<span class="text-gray-400">There are no products currently under this category.</span>
		</div>
		
		<!-- Pagination -->
		<Pagination class="my-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
		
		
	</LoadingPane>
</template>
<script>
import LoadingPane from '@leaf/ui/components/LoadingPane'
import Pagination from '@leaf/ui/mixins/Pagination'
import ProductCard from './ProductCard'

export default {
	props: [ 'activeCat' ],
	components: {
		LoadingPane,
		ProductCard,
	},
	mixins: [ Pagination ],
	watch: {
		activeCat() {
			this.loadPage(0);
		},
	},
	computed: {
		catTitle() {
			if (!this.activeCat) return 'All Products';
			else return this.activeCat.catName;
		}
	},
	data() {
		return {
			loading: false,
			products: [],
		};
	},
	methods: {
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				if (this.activeCat) queries.push(`cat=${this.activeCat.catID}`);
				
				const res = await this.$api.request('GET', `products?${queries.join('&')}`);
				this.curPage = page;
				this.totalRows = res.total;
				
				this.products = res.results;
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.loadPage(0);
	}
}
</script>