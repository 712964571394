import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/scss/main.scss'

import Plugins from './plugins'

// Entry point
(async function() {
	const app = createApp(App)
	
	// Init plugins
	await Plugins.init(app);
	
	// Mount app
	app.use(store).use(router).mount('#app')
})();


