<style lang="scss" scoped>
.modal-container {
	max-height: calc(100vh - 1rem);
}

// Modal transitions
.modal-enter,
.modal-enter-active,
.modal-leave-active  {
	transition: all 0.2s ease;
	.modal-container { transition: all 0.2s ease; }
}
.modal-enter-from, .modal-leave-to {
	opacity: 0;
	.modal-container { transform: scale(1.1); }
}

</style>
<template>
	<transition name="modal">
		<!-- Modal backdrop -->
		<div
			v-if="modelValue"
			@click="$emit('update:modelValue', false)"
			class="modal-cover bg-black fixed z-10 inset-0 w-full h-full flex justify-center items-center bg-opacity-75">
			
			<!-- This is the container for the modal -->
			<div
				@click.stop
				class="modal-container p-2 bg-white rounded-lg overflow-y-auto"
				:class="containerClass">
				<slot></slot>
			</div>
			
		</div>
	</transition>
</template>
<script>
export default {
	props: {
		modelValue: {
			type: Boolean,
			required: true
		},
		containerClass: {
			type: [ String, Array, Object ],
			default: 'px-4 py-3',
		}
	},
	model: {
		prop: 'modelValue',
		event: 'update:modelValue'
	}
}
</script>