import { emitter } from '../../utils'
import ConfirmDialog from './ConfirmDialog'


export default {
	install(app) {
		// Register container as global component
		app.component('confirm-dialog', ConfirmDialog);
		
		const handler = async (options) => {
			return new Promise((resolve, reject) => {
				if (!options) {
					reject('Invalid options');
					return;
				}
				// Trigger the confirm modal to show
				emitter.emit('confirm-dialog/open', {
					...options,
					callback: res => resolve(res),
				});
			});
		}
		
		const version = Number(app.version.split('.')[0]);
		if (version == 3) app.config.globalProperties.$confirm = handler;
		else if (version == 2) app.prototype.$confirm = handler;
	}
}