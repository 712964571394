<template>
	<div class="flex flex-col">
		
		<Navbar class="h-12" />
		
		<confirm-dialog />
		<toast-container />
		
		<div class="mt-12">
			<router-view v-slot="slotProps">
				<transition name="fade" mode="out-in">
					<component :is="slotProps.Component"></component>
				</transition>
			</router-view>
		</div>
		
	</div>
</template>
<script>
import Navbar from '@/views/Navbar/Navbar'
export default {
	components: {
		Navbar,
	}
}
</script>