<template>
	<div>
		<div @click="toggle">
			<slot name="header" v-bind="{ expanded }"></slot>
		</div>
		
		<transition name="accordion"
			@before-enter="beforeEnter" @enter="enter"
			@before-leave="beforeLeave" @leave="leave">
			
			<div v-show="expanded" class="overflow-hidden transition-all duration-200">
				<slot></slot>
			</div>
			
		</transition>
		
	</div>
</template>
<script>
export default {
	data() {
		return {
			expanded: false,
		};
	},
	methods: {
		toggle() { this.expanded = !this.expanded; },
		
		// Transition methods
		beforeEnter(el) { el.style.height = '0'; },
		enter(el) { el.style.height = el.scrollHeight + 'px'; },
		beforeLeave(el) { el.style.height = el.scrollHeight + 'px'; },
		leave(el) { el.style.height = '0'; }
	}
}
</script>