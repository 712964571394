<style lang="scss" scoped>


._preloader {
	border-color: theme('colors.gray.200');
	border-top-color: theme('colors.primary.400');
}

</style>

<template>
	<div>
		<div class="_preloader animate-spin rounded-full border-4 w-8 h-8 block mx-auto"></div>
	</div>
</template>

<script>
export default {
	props: {
		size: {
			type: String,
			default: '40px'
		}
	}
}
</script>