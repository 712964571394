<style lang="scss" scoped>
.cat-link {
	@apply py-1 select-none text-gray-500 hover:text-primary-300 transition-colors cursor-pointer;
}
.subcat-link {
	@apply px-2 py-1 text-gray-400 hover:text-primary-400 transition-colors cursor-pointer;
}
</style>
<template>
	<ul>
		<li>
			<!-- View all categories -->
			<div
				@click="$emit('deselect')"
				class="cat-link flex justify-between items-center">
				<span class="font-atma font-semibold text-lg">All</span>
			</div>
		</li>
		<li>
			<!-- Uncategorized items -->
			<div
				@click="$emit('selected', { catID: null, catName: 'Uncategorized' })"
				class="cat-link flex justify-between items-center">
				<span class="font-atma font-semibold text-lg">Uncategorized</span>
			</div>
		</li>
		<hr class="my-1">
		
		
		<li v-for="c in categories">
			
			<!-- If subcategories exist, render accordion -->
			<Accordion v-if="c.subcategories.length">
				<template #header="{ expanded }">
					<div
						@click="$emit('selected', c)"
						class="cat-link flex justify-between items-center">
						<span class="font-atma font-semibold text-lg">{{ c.catName }}</span>
						<icon class="w-5 h-5" :data="expanded ? mdiChevronUp : mdiChevronDown" />
					</div>
				</template>
				
				<ul class="font-atma">
					<li v-for="sc in c.subcategories"
						@click="$emit('selected', sc)"
						class="subcat-link">
						{{ sc.catName }}
					</li>
				</ul>
			</Accordion>
			
			
			<!-- If no subcategories, just render a simple link -->
			<div
				v-else
				@click="$emit('selected', c)"
				class="cat-link flex justify-between items-center">
				<span class="font-atma font-semibold text-lg">{{ c.catName }}</span>
			</div>
		</li>
	</ul>
</template>
<script>
import Accordion from '@leaf/ui/components/Accordion'
import { mdiChevronUp, mdiChevronDown, mdiChevronRight } from '@mdi/js'
export default {
	props: [ 'categories' ],
	components: {
		Accordion,
	},
	data() {
		return {
			mdiChevronUp,
			mdiChevronDown,
			mdiChevronRight,
		}
	}
}
</script>