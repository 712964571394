export function loadScript(url) {
	return new Promise((resolve, reject) => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.src = url;
		document.body.appendChild(s);

		s.addEventListener('load', resolve);
		s.addEventListener('abort', reject);
		s.addEventListener('error', reject);
	});
}


export const debounce = (callback, delay = 250) => {
	let interval;
	return (...args) => {
		clearTimeout(interval);
		interval = setTimeout(() => {
			interval = null;
			callback(...args);
		}, delay);
	}
}


// Event bus
import mitt from 'mitt'
export const emitter = mitt();
